import "vant/es/notify/style/less";
import _Notify from "vant/es/notify";
import "vant/es/picker/style/less";
import _Picker from "vant/es/picker";
import "vant/es/popup/style/less";
import _Popup from "vant/es/popup";
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store';
export default defineComponent({
  name: 'AreaInfo',
  components: {
    [_Popup.name]: _Popup,
    [_Picker.name]: _Picker
  },

  setup() {
    const customFieldName = {
      text: 'name',
      value: 'orgId',
      children: 'areas'
    };
    const myOrgAndArea = {
      orgName: '',
      areaName: '',
      currentOrgId: '',
      currentAreaId: '',
      defaultIndex: [0, 0]
    };
    const store = useStore();
    const orgs = store.state.session.orgs;
    myOrgAndArea.currentOrgId = store.state.session.info.orgId;
    myOrgAndArea.currentAreaId = store.state.session.info.areaId;
    const showAreaChangePicker = ref(false);
    const areaName = ref(store.getters.myAreaName);
    const areaNames = ref(store.getters.areaNames); // 遍历组织列表，查找匹配的部门

    for (let i = 0; i < orgs.length; i++) {
      if (myOrgAndArea.currentOrgId === orgs[i].orgId) {
        myOrgAndArea.orgName = orgs[i].name; // 在找到部门后，立即查找匹配的小区

        for (let j = 0; j < orgs[i].areas.length; j++) {
          if (myOrgAndArea.currentAreaId === orgs[i].areas[j].uuid) {
            myOrgAndArea.areaName = orgs[i].areas[j].name; // defaultActive = orgs[i].areas[j].uuid; // 设置默认选中的小区UUID

            break; // 找到匹配的小区后退出内层循环
          }
        }

        break; // 找到匹配的部门后退出外层循环
      }
    }

    const onConfirm = option => {
      // if (store.state.areas) {
      console.log('option', option);
      console.log('option[0].orgId', option[0].orgId);
      console.log('option[1].id', option[1].id);
      store.dispatch('changeMyArea', option).then(() => {
        _Notify({
          type: 'success',
          message: '切换成功，即将重新加载页面'
        });

        setTimeout(() => {
          location.reload();
        }, 2000);
      }).catch(() => {// location.reload()
      }); // }
    };

    return {
      showAreaChangePicker,
      areaName,
      areaNames,
      orgs,
      onConfirm,
      customFieldName,
      myOrgAndArea
    };
  }

});