import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import RootStateTypes from '@/store/interface'
import { getInfo, login, logout, switchArea } from '@/api/session'
import { getAllDict } from '@/api/basic'
import router, { asyncRoutes, constantRoutes } from '@/router'
import { RouteRecordRaw } from 'vue-router'
import { Permission } from '@/interfaces/session'

export const cleanRoutePermissions = (routes: Array<RouteRecordRaw>): void => {
  routes.forEach(route => {
    if (route.children) {
      cleanRoutePermissions(route.children)
    }
    if (route.meta) {
      delete route.meta.permissions
    }
  })
}

export const filterAsyncRoutes = (routes: Array<RouteRecordRaw>, permissions: Array<Permission>): Array<RouteRecordRaw> => {
  const res: Array<RouteRecordRaw> = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions)
      }
      res.push(tmp)
    }
  })

  return res
}

function hasPermission (permissions: Array<Permission>, route: RouteRecordRaw) {
  return permissions.some(item => {
    if (route.path === `/${item.path}` || (route.meta && item.path === route.meta.permissionName)) {
      if (route.meta && route.meta.permissionName) {
        item.childModels?.forEach(child => {
          if (child.path === route.meta?.permissionName) {
            route.meta.permissions = child.permissions
          }
        })
      }
      return true
    } else if (item.childModels) {
      item.childModels.forEach(child => {
        if (child.path === route.path || child.path === route.meta?.permissionName) {
          if (route.meta) {
            route.meta.permissions = child.permissions
          }
        }
      })
      return item.childModels.some(child => child.path === route.path || child.path === route.meta?.permissionName)
    }
    return false
  })
}

export const key: InjectionKey<Store<RootStateTypes>> = Symbol('vuex-store')

export const store = createStore<RootStateTypes>({
  state: {
    userInfo: undefined,
    area: undefined,
    areas: undefined,
    allDict: [],
    patrolPointTypeList: undefined,
    permissions: undefined,
    routes: [],
    addRoutes: [],
    materialInventoryDetails: [],
    session: {}
  },
  mutations: {
    SET_MY_SESSION (state, session) {
      state.session = session
    },
    SET_USER_INFO (state, userInfo) {
      state.userInfo = userInfo
    },
    SET_MY_AREA (state, area) {
      state.area = area
    },
    SET_MY_AREAS (state, areas) {
      state.areas = areas
    },
    SET_ALL_DICT (state, allDict) {
      state.allDict = allDict
    },
    SET_PERMISSIONS (state, permissions) {
      state.permissions = permissions
    },
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_MATERIAL_INVENTORY_DETAILS: (state, details) => {
      state.materialInventoryDetails = details
    }
  },
  getters: {
    name: state => state.userInfo?.username,
    userId: state => state.userInfo?.id,
    searchHistoryKey: state => `fsc_search_history_${state.area?.id}_${state.userInfo?.id}`,
    myAreaId: state => state.area?.id,
    myAreaName: state => state.area?.name,
    areaNames: state => state.areas?.map(item => item.name),
    dictByKey: state => (key: string) => state.allDict.filter(item => item.categoryId === key),
    dictValueByKey: state => (key: string) => state.allDict.find(item => item.key === key)?.value
  },
  actions: {
    login ({ commit }, info) {
      const {
        username,
        password,
        captcha
      } = info
      return new Promise((resolve, reject) => {
        login({
          username: username.trim(),
          pass: password,
          captcha
        })
          .then(response => {
            commit('SET_USER_INFO', response.data.info)
            resolve(response.data)
          }).catch(error => {
            reject(error)
          })
      })
    },

    getInfo ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(async response => {
            if (response.data.areas) {
              commit('SET_MY_AREAS', response.data.areas)
              commit('SET_MY_SESSION', response.data)
              const areaIndex = response.data.areas.findIndex(item => item.uuid === response.data.info.areaId)
              if (areaIndex > -1) {
                commit('SET_MY_AREA', response.data.areas[areaIndex])
              } else {
                commit('SET_MY_AREA', response.data.areas[0])
              }
            } else {
              reject(new Error('出错啦，请联系管理员'))
            }
            commit('SET_USER_INFO', response.data.info)
            commit('SET_PERMISSIONS', response.data.permission)
            await dispatch('fetchAllDict')
            const accessRoutes = await dispatch('generateRoutes')
            accessRoutes.forEach((route: RouteRecordRaw) => {
              router.addRoute(route)
            })
            resolve('')
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_MY_AREA', undefined)
            commit('SET_USER_INFO', undefined)
            resolve({})
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchAllDict ({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.area) {
          getAllDict(state.area.id)
            .then(response => {
              commit('SET_ALL_DICT', response.data)
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    },

    changeMyArea ({ commit }, option:any) {
      return new Promise((resolve, reject) => {
        // commit('SET_MY_AREA', area)
        switchArea(option[0].orgId, option[1].id)
          .then(() => {
            resolve(option[1].id)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    generateRoutes ({ commit, state }) {
      return new Promise(resolve => {
        let accessedRoutes
        if (state.permissions) {
          accessedRoutes = filterAsyncRoutes(asyncRoutes, state.permissions)
        } else {
          cleanRoutePermissions(asyncRoutes)
          accessedRoutes = [...asyncRoutes]
        }
        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      })
    }
  }
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useStore () {
  return baseUseStore(key)
}

export default store
