import request from '@/utils/request'
import { AxiosPromise } from 'axios'
import {
  WechatJSSignature,
  DictItem,
  House,
  Parking,
  Plate,
  AccountRequestParam,
  AccountResponse, OrganizationItem
} from '@/interfaces/basic'

export function getDictById (id: string): AxiosPromise<Array<DictItem>> {
  return request({
    url: `/dicts/${id}`,
    method: 'get'
  })
}

export function getAllDict (areaId: number): AxiosPromise<Array<DictItem>> {
  return request({
    url: `/mobile/areas/${areaId}/basic/dictionaries`,
    method: 'get'
  })
}

export function getHouseById (areaId: number, houseId: number): AxiosPromise<House> {
  return request({
    url: `/mobile/areas/${areaId}/basic/houses/${houseId}`,
    method: 'get'
  })
}

export function getParkingById (areaId: number, parkingId: number): AxiosPromise<Parking> {
  return request({
    url: `/mobile/areas/${areaId}/basic/parking/${parkingId}`,
    method: 'get'
  })
}

export function getPlateById (areaId: number, plateId: number): AxiosPromise<Plate> {
  return request({
    url: `/mobile/areas/${areaId}/basic/plates/${plateId}`,
    method: 'get'
  })
}

export function getWechatJSSignature (url: string): AxiosPromise<WechatJSSignature> {
  return request({
    url: '/mobile/account/wechat/getSignature',
    method: 'get',
    params: {
      signUrl: url
    }
  })
}

export function getAccounts (areaId: number, params: AccountRequestParam): AxiosPromise<AccountResponse> {
  return request({
    url: `/mobile/areas/${areaId}/basic/accounts`,
    method: 'get',
    params
  })
}

export function getOrganization (areaId: number): AxiosPromise<OrganizationItem[]> {
  return request({
    url: `/mobile/areas/${areaId}/basic/operate-organization/sub`,
    method: 'get'
  })
}
