import request from '@/utils/request'
import { LoginForm, LoginResponse, BindingForm } from '@/interfaces/session'
import { AxiosPromise } from 'axios'

export function login (data: LoginForm): AxiosPromise<LoginResponse> {
  return request({
    url: '/session',
    method: 'post',
    data
  })
}

export function logout (): AxiosPromise {
  return request({
    url: '/session',
    method: 'delete'
  })
}

export function getInfo (): AxiosPromise<LoginResponse> {
  return request({
    url: '/session',
    method: 'get'
  })
}

export function bindWechat (data: BindingForm): AxiosPromise {
  return request({
    url: '/mobile/account/wechat/binding',
    method: 'post',
    data
  })
}

export function switchArea (orgId:any, areaId:any): AxiosPromise {
  console.log('switchAreaorgId', orgId)
  console.log('switchAreaareaId', areaId)
  return request({
    url: `/session/${orgId}/${areaId}`,
    method: 'get'
  })
}
